import React from 'react';
import './MainView.css';

const MainView: React.FC = () => {
  return (
    <div>
      <div id="additional-messages" className="additional-messages">
        <h2>Pulcer: Gamble Better</h2>
        <h3>The best free gambling apps on earth</h3>
      </div>

      <div id="additional-messages" className="additional-messages">
        <h3>
          Follow me on <a href="https://twitter.com/AnthonyHowell88" target="_blank">X</a>
        </h3>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id="apple-apps" className="section" style={{ width: '50%' }}>
          <h2>iOS</h2>
          <div className="container">
            <p><a className="button" href="https://apps.apple.com/us/app/poker-legend-5/id6474522484">Poker Legend 5</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/poker-trainer-5/id6504971242">Poker Trainer 5</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/parlay-calc/id1537337981">Parlay Calculator</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/sports-arb-calc/id1535295107">Sports Arbitrage Calculator</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/gambling-journal-5/id6499230607">Gambling Journal 5</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/poker-game-settler/id6463663656">Poker Game Settler</a></p>
            <p><a className="button" href="https://apps.apple.com/ng/app/advanced-arb/id6473758536">Advanced Arbitrage Calculator</a></p>
            <p><a className="button" href="https://apps.apple.com/us/app/plo-legend/id6474490099?mt=12">Poker Legend 5 Pro</a></p>
          </div>
        </div>

        <div id="android-apps" className="section" style={{ width: '50%' }}>
          <h2>Android</h2>
          <div className="container">
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.pokerlegend">Poker Legend 5</a></p>
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.pokertrainer5">Poker Trainer 5</a></p>
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.parlaycalculator">Parlay Calculator</a></p>
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.sportsarbcalc">Sports Arbitrage Calculator</a></p>
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.gamblingjournal5&hl=en&gl=US">Gambling Journal 5</a></p>
            <p><a className="button" href="https://play.google.com/store/apps/details?id=net.pulcer.pokegamesettler">Poker Game Settler</a></p>
          </div>
        </div>

        <div id="websites" className="section" style={{ width: '50%' }}>
          <h2>Web</h2>
          <div className="container">
            <p><a className="button" href="https://pokerlegend5.com/">Poker Legend 5</a></p>
            <p><a className="button" href="https://arbulator.com/parlay">Parlay Calculator</a></p>
            <p><a className="button" href="https://arbulator.com/arbitrage">Sports Arbitrage Calculator</a></p>
            <p><a className="button" href="https://gamblingjournal5.com/">Gambling Journal 5</a></p>
          </div>
        </div>
      </div>

      <div id="contact-me" className="section">
        <h2>Contact Me/Hire Me</h2>
        <div className="container">
          <p><a className="button" href="mailto:anthony@pulcer.net">Contact Me</a></p>
        </div>
      </div>

      <div id="additional-messages" className="additional-messages">
        <h3>Please give me feedback. I am always looking to improve. Just click "Contact Me"</h3>
        <h3>Privacy Policy: Minimal to no data is collected on apps or website</h3>
      </div>
    </div>
  );
};

export default MainView;